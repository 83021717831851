import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {VerificationType} from './http/verification-type.enum';
import {LoginResponse, UserData} from '../model/index';

/**
 * Created by gnuni.gevorgyan on 9/27/2016.
 */
@Injectable()
export abstract class AuthenticationService {

  public redirectUrl: string;

  /**
   * Login user
   */
  public abstract login(username: string, password: string): Observable<LoginResponse>;

  /**
   * Verify 2FA code
   */
  public abstract verifyCode(code: string, type?: VerificationType): Observable<boolean>;

  /**
   * Resend 2FA code
   */
  public abstract resend(type: VerificationType): Observable<boolean>;

  /**
   * Login as guest user
   */
  public abstract loginAsGuest(): Observable<LoginResponse>;

  /**
   * Logout user
   * @param {boolean} [withoutRequest] - if set, will not proceed with logout request
   */
  public abstract logout(withoutRequest?: boolean): void;

  /**
   * @returns true if user is logged on
   */
  public abstract isLoggedOn(): Observable<boolean>;

  /**
   * @returns true if user is logged on
   */
  public abstract isLoggedOnAsGuestUser(): Observable<boolean>;

  /**
   * @returns User data
   */
  public abstract getUserData(): Observable<UserData>;

  /**
   * @returns Check tokens
   */
  public abstract checkToken(): void;
}
