import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {ApplicationPropertiesService} from '@synisys/idm-application-properties-service-client-js';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {_throw as throwError} from 'rxjs/observable/throw';
import {catchError, map, mergeMap, takeUntil} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {Subject} from 'rxjs/Subject';
import {noop} from 'rxjs/util/noop';
import {LoginResponse, UserData} from '../../model/index';
import {AuthenticationService} from '../authentication.service';
import {CookieService} from '../cookie-service';
import {AuthSettings} from './auth-settings.config';
import {HttpClientWrapper} from './http-client-wrapper';
import {LoginResponseType} from './login-responses.enum';
import {VerificationType} from './verification-type.enum';
import {HttpUrlStandardEncodingCodec} from '../util/http-url-standard-encoding-spec';

/**
 * Http implementation of AuthenticationService
 * @author gnuni.gevorgyan
 */
@Injectable()
export class HttpAuthenticationService extends AuthenticationService
    implements OnDestroy {
    protected static _instance:
        | HttpAuthenticationService
        | undefined = undefined;

    private destroySubject$: Subject<void> = new Subject<void>();

    private static addHeader(options: Object) {
        if (!options) {
            const headers = new HttpHeaders();
            options = {headers: headers};
        }
        options['headers'].append('', '');
        return options;
    }

    /**
     * Handle errors
     * @param error
     * @returns {ErrorObservable}
     */
    private static handleError(error: any) {
        const errMsg = error.message
            ? error.message
            : error.status
            ? `${error.status} - ${error.statusText}`
            : 'Server error';
        console.error(errMsg);
        return throwError(errMsg);
    }

    public redirectUrl = '';

    protected loggedOn: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    protected loggedOnAsGuest = new ReplaySubject<boolean>(1);
    protected authServiceUrl = new ReplaySubject<string>(1);
    protected userData = new ReplaySubject<UserData>(1);
    private username: string;

    constructor(
        protected http: HttpClient,
        protected appProperties: ApplicationPropertiesService,
        protected cookieService: CookieService
    ) {
        super();
        this.appProperties
            .getProperty(AuthSettings.OAUTH_SERVICE_URL_KEY)
            .then(url => {
                this.authServiceUrl.next(url);
                this.authServiceUrl.complete();
            })
            .catch(HttpAuthenticationService.handleError);
        this.username = this.getUsername();
        this.checkToken();
    }

    public ngOnDestroy() {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    public static getInstance(
        http: HttpClient,
        appProperties: ApplicationPropertiesService,
        cookieService: CookieService,
        _?: HttpClientWrapper
    ) {
        if (this._instance === undefined) {
            this._instance = new HttpAuthenticationService(
                http,
                appProperties,
                cookieService
            );
        }
        return this._instance;
    }

    /**
     *
     * @returns current user data
     */
    public getUserData(): Observable<UserData> {
        return this.userData.asObservable();
    }

    /**
     *
     * @returns true if user is logged on
     */
    public isLoggedOn(): Observable<boolean> {
        return this.loggedOn.asObservable();
    }

    /**
     * Login User
     */
    public login(
        username: string,
        password: string
    ): Observable<LoginResponse> {
        const httpParams = new HttpParams({
            encoder: new HttpUrlStandardEncodingCodec(),
        })
            .set('grant_type', 'password')
            .set('username', username)
            .set('password', password);
        this.setUsername(username);
        const options = this.oauthClientHeaders();

        return this.authServiceUrl.pipe(
            mergeMap((url: string) =>
                this.http
                    .post(
                        url + AuthSettings.OAUTH_SERVICE_TOKEN_ENDPOINT,
                        httpParams,
                        options
                    )
                    .pipe(
                        mergeMap((response: HttpResponse<Object>) => {
                            if (response.status === 200) {
                                const body: any = response.body;
                                while (
                                    this.cookieService.check(
                                        AuthSettings.TOKEN_KEY_NAME
                                    )
                                ) {
                                    this.cookieService.delete(
                                        AuthSettings.TOKEN_KEY_NAME,
                                        '/'
                                    );
                                }
                                this.cookieService.set(
                                    AuthSettings.TOKEN_KEY_NAME,
                                    body.access_token,
                                    undefined,
                                    '/'
                                );
                                return this.userDataObservable().pipe(
                                    map(() => {
                                        this.loggedOn.next(true);
                                        if (body.isNeedVerification) {
                                            return new LoginResponse(
                                                LoginResponseType.NEED_VERIFICATION,
                                                '',
                                                [],
                                                body.verificationType
                                            );
                                        }
                                        return new LoginResponse(
                                            LoginResponseType.SUCCESS
                                        );
                                    })
                                );
                            } else {
                                return of(
                                    new LoginResponse(
                                        LoginResponseType.FAILED,
                                        'auth_bad_credentials'
                                    )
                                );
                            }
                        })
                    )
            ),
            catchError((httpErrorResponse: HttpErrorResponse) =>
                of(
                    this.handleLoginError(
                        httpErrorResponse.error,
                        httpErrorResponse.status
                    )
                )
            )
        );
    }

    public verifyCode(
        code: string,
        type?: VerificationType
    ): Observable<boolean> {
        const options = this.oauthClientHeaders();

        let params: HttpParams = new HttpParams()
            .set('verificationCode', code)
            .set('token', this.cookieService.get(AuthSettings.TOKEN_KEY_NAME))
            .set('userLogin', this.getUsername());
        type && (params = params.set('type', type));
        options['params'] = params;

        return this.authServiceUrl.pipe(
            mergeMap((url: string) =>
                this.http.get(url + AuthSettings.VERIFY_CODE, options)
            ),
            map((response: HttpResponse<boolean>) => response.body)
        );
    }

    public resend(type: VerificationType): Observable<boolean> {
        const username = this.getUsername();
        const options = this.oauthClientHeaders();
        options['params'] = new HttpParams()
            .set('userLogin', username)
            .set('token', this.cookieService.get(AuthSettings.TOKEN_KEY_NAME))
            .set('type', type);
        return this.authServiceUrl.pipe(
            mergeMap((url: string) =>
                this.http.post(url + AuthSettings.RESEND, undefined, options)
            ),
            map(() => true),
            catchError(() => of(false))
        );
    }

    public loginAsGuest(): Observable<LoginResponse> {
        return this.login(
            AuthSettings.GUEST_USER_NAME,
            AuthSettings.GUEST_PASSWORD
        );
    }

    /**
     * @inheritDoc
     */
    public logout(withoutRequest?: boolean): void {
        if (!withoutRequest) {
            this.authServiceUrl
                .pipe(
                    mergeMap(url => {
                        return this.http
                            .get(
                                url + AuthSettings.OAUTH_SERVICE_LOGOUT_ENDPOINT
                            )
                            .pipe(
                                map(resp => {
                                    this.cookieService.delete(
                                        AuthSettings.TOKEN_KEY_NAME,
                                        '/'
                                    );
                                    this.cookieService.delete(
                                        AuthSettings.IS_SAML_USER,
                                        '/'
                                    );
                                })
                            );
                    }),
                    takeUntil(this.destroySubject$)
                )
                .subscribe(noop, err => console.error(err));
        } else {
            this.cookieService.delete(AuthSettings.TOKEN_KEY_NAME, '/');
        }
        this.loggedOn.next(false);
    }

    public isLoggedOnAsGuestUser(): Observable<boolean> {
        return this.loggedOnAsGuest.asObservable();
    }

    public checkToken() {
        const token: string = this.cookieService.get(
            AuthSettings.TOKEN_KEY_NAME
        );
        if (token) {
            this.checkTokenValidity();
        } else {
            this.loggedOn.next(false);
            this.loggedOnAsGuest.next(false);
        }
        this.loggedOn
            .pipe(
                mergeMap((resp: boolean) => {
                    if (resp) {
                        return this.userDataObservable();
                    } else {
                        return of(null);
                    }
                }),
                takeUntil(this.destroySubject$)
            )
            .subscribe(noop, err => console.error(err));
    }

    protected checkTokenValidity() {
        const httpParams = new HttpParams().set(
            'token',
            this.cookieService.get(AuthSettings.TOKEN_KEY_NAME)
        );
        const options = this.oauthClientHeaders();
        this.authServiceUrl
            .pipe(
                mergeMap(url =>
                    this.http.post(
                        url + AuthSettings.OAUTH_SERVICE_CHECK_TOKEN_ENDPOINT,
                        httpParams,
                        options
                    )
                ),
                takeUntil(this.destroySubject$)
            )
            .subscribe(
                (response: HttpResponse<Object>) => {
                    this.loggedOn.next(true);
                    const data: any = response.body;
                    this.checkIfUserIsGuest(Number(data.user_name));
                },
                (error: any) => {
                    this.cookieService.delete(AuthSettings.TOKEN_KEY_NAME);
                    this.loggedOn.next(false);
                    this.checkIfUserIsGuest(undefined);
                }
            );
    }

    protected checkIfUserIsGuest(userId: number) {
        if (userId === AuthSettings.GUEST_USER_ID) {
            this.loggedOnAsGuest.next(true);
        } else {
            this.loggedOnAsGuest.next(false);
        }
    }

    protected userDataObservable(): Observable<any> {
        return this.authServiceUrl.pipe(
            mergeMap((url: string) =>
                this.http.get(
                    url + AuthSettings.OAUTH_SERVICE_USERDATA_ENDPOINT,
                    {
                        observe: 'response',
                    }
                )
            ),
            map((resp: HttpResponse<object>) => {
                const userData: UserData = new UserData();
                if (resp.status === 200) {
                    const data = resp.body;
                    userData.fullName = data['userName'];
                    userData.userId = Number(data['userId']);
                    userData.username = this.getUsername();
                    this.checkIfUserIsGuest(userData.userId);
                }
                this.userData.next(userData);
                return true;
            })
        );
    }

    protected oauthClientHeaders() {
        const encoded = btoa(
            AuthSettings.CLIENT_ID + ':' + AuthSettings.CLIENT_SECRET
        );
        const headers = new HttpHeaders()
            .append('Authorization', 'Basic ' + encoded)
            .append(
                'Content-Type',
                'application/x-www-form-urlencoded; charset=utf-8'
            );
        return {headers: headers, observe: 'response' as 'response'};
    }

    protected handleLoginError(
        errorObject: any,
        status: number
    ): LoginResponse {
        if (status === 400 && errorObject.error === 'invalid_grant') {
            if (
                errorObject.error_description ===
                'User credentials have expired'
            ) {
                return new LoginResponse(
                    LoginResponseType.EXPIRED,
                    'auth_expired_credentials'
                );
            }
            if (errorObject.error_description === 'User account is locked') {
                return new LoginResponse(
                    LoginResponseType.UM_LOCK,
                    'auth_user_lock'
                );
            }
        } else if (status === 403) {
            if (errorObject.error !== 'user_block') {
                throw new Error('Unknown error type ' + errorObject.error);
            }
            return new LoginResponse(
                LoginResponseType.BLOCK,
                'auth_user_block',
                [],
                undefined,
                errorObject.error_message_lastmodified,
                errorObject.lockTimeInMinutes
            );
        }
        return new LoginResponse(
            LoginResponseType.FAILED,
            'auth_bad_credentials'
        );
    }

    protected setUsername(username: string): void {
        this.username = username;
        localStorage.setItem('username', username);
    }

    protected getUsername(): string {
        return this.username || localStorage.getItem('username');
    }
}
