import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import {Inject, Injectable, InjectionToken, Optional} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {RequestPatternModel} from '../../../model/request-pattern.model';
import {CookieService} from '../../cookie-service';
import {addToken} from './token.utilities';

// tslint:disable-next-line:no-any
export type anyRequest = HttpRequest<any>;

export const EXCLUDE_AUTH_TOKEN = new InjectionToken<RequestPatternModel>(
    'exclude auth token'
);

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
    constructor(
        private cookieService: CookieService,
        @Inject(EXCLUDE_AUTH_TOKEN)
        @Optional()
        private readonly excludePatterns:
            | RequestPatternModel[]
            | null
            | undefined
    ) {}

    public intercept(
        request: anyRequest,
        next: HttpHandler
    ): Observable<HttpEvent<{}>> {
        if (
            request.headers.has('Authorization') ||
            request.url.includes('/resetPassword') ||
            (request.method === 'GET' &&
                (request.url.includes('/language') ||
                    request.url.includes('/message') ||
                    request.url.includes('/pages/shared'))) ||
            this.isPropertiesRequest(request) ||
            this.isExcluded(request)
        ) {
            return next.handle(request);
        }
        return next.handle(addToken(request, this.cookieService));
    }

    private isPropertiesRequest(request: anyRequest): boolean {
        return (
            request.url.endsWith('properties/urls') ||
            request.url.endsWith('json')
        );
    }

    private isExcluded(request: anyRequest): boolean {
        if (
            this.excludePatterns === undefined ||
            this.excludePatterns === null
        ) {
            return false;
        }
        return this.excludePatterns.some(pattern => pattern.match(request));
    }
}
