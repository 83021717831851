/**
 * @author Vahagn Lazyan.
 * @since 2.0.0
 */
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {HttpObserve} from '@angular/common/http/src/client';
import {HttpEvent} from '@angular/common/http/src/response';

import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/throw';
import {CookieService} from '../cookie-service';

/**
 * Wrapper for angular's {@link HttpClient}.
 */
@Injectable()
export class HttpClientWrapper {
  private static isInited = false;

  private tokenKey = 'access_token';

  constructor(private http: HttpClient, private cookieService: CookieService) {
    if (HttpClientWrapper.isInited) {
      throw Error('HttpClientWrapper is already instantiated');
    }
    HttpClientWrapper.isInited = true;
  }

  /**
   * Construct a request which interprets the body as an `ArrayBuffer` and returns it.
   *
   * @return an `Observable` of the body as an `ArrayBuffer`.
   */
  public request(method: string, url: string, options: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<ArrayBuffer>;
  /**
   * Construct a request which interprets the body as a `Blob` and returns it.
   *
   * @return an `Observable` of the body as a `Blob`.
   */
  public request(method: string, url: string, options: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<Blob>;
  /**
   * Construct a request which interprets the body as text and returns it.
   *
   * @return an `Observable` of the body as a `string`.
   */
  public request(method: string, url: string, options: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<string>;
  /**
   * Construct a request which interprets the body as an `ArrayBuffer` and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `ArrayBuffer`.
   */
  public request(method: string, url: string, options: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    observe: 'events';
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpEvent<ArrayBuffer>>;
  /**
   * Construct a request which interprets the body as an `Blob` and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Blob`.
   */
  public request(method: string, url: string, options: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpEvent<Blob>>;
  /**
   * Construct a request which interprets the body as text and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `string`.
   */
  public request(method: string, url: string, options: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<HttpEvent<string>>;
  /**
   * Construct a request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Object`.
   */
  public request(method: string, url: string, options: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    reportProgress?: boolean;
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpEvent<any>>;
  /**
   * Construct a request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `R`.
   */
  public request<R>(method: string, url: string, options: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    reportProgress?: boolean;
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpEvent<R>>;
  /**
   * Construct a request which interprets the body as an `ArrayBuffer` and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `ArrayBuffer`.
   */
  public request(method: string, url: string, options: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpResponse<ArrayBuffer>>;
  /**
   * Construct a request which interprets the body as a `Blob` and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Blob`.
   */
  public request(method: string, url: string, options: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Blob>>;
  /**
   * Construct a request which interprets the body as text and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `string`.
   */
  public request(method: string, url: string, options: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<HttpResponse<string>>;
  /**
   * Construct a request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Object`.
   */
  public request(method: string, url: string, options: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    reportProgress?: boolean;
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Object>>;
  /**
   * Construct a request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `R`.
   */
  public request<R>(method: string, url: string, options: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    reportProgress?: boolean;
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<R>>;
  /**
   * Construct a request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Object`.
   */
  public request(method: string, url: string, options?: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    responseType?: 'json';
    reportProgress?: boolean;
    withCredentials?: boolean;
  }): Observable<Object>;
  /**
   * Construct a request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `R`.
   */
  public request<R>(method: string, url: string, options?: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    responseType?: 'json';
    reportProgress?: boolean;
    withCredentials?: boolean;
  }): Observable<R>;
  /**
   * Construct a request in a manner where response type and requested `Observable` are not known
   * statically.
   *
   * @return an `Observable` of whatever was requested, typed to `any`.
   */
  public request(method: string, url: string, options?: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    observe?: HttpObserve;
    reportProgress?: boolean;
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
    withCredentials?: boolean;
  }): Observable<any>;
  /**
   * Constructs an `Observable` for a particular HTTP request that, when subscribed,
   * fires the request through the chain of registered interceptors and on to the
   * server.
   */
  public request(method: string, url: string, options: {
    body?: any;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    observe?: HttpObserve;
    reportProgress?: boolean;
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
    withCredentials?: boolean;
  } = {}): Observable<any> {
    return this.http.request(method, url, this.getRequestOptionArgs(options));
  }

  /**
   * Construct a DELETE request which interprets the body as an `ArrayBuffer` and returns it.
   *
   * @return an `Observable` of the body as an `ArrayBuffer`.
   */
  public delete(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<ArrayBuffer>;
  /**
   * Construct a DELETE request which interprets the body as a `Blob` and returns it.
   *
   * @return an `Observable` of the body as a `Blob`.
   */
  public delete(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<Blob>;
  /**
   * Construct a DELETE request which interprets the body as text and returns it.
   *
   * @return an `Observable` of the body as a `string`.
   */
  public delete(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<string>;
  /**
   * Construct a DELETE request which interprets the body as an `ArrayBuffer` and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `ArrayBuffer`.
   */
  public delete(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpEvent<ArrayBuffer>>;
  /**
   * Construct a DELETE request which interprets the body as a `Blob` and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Blob`.
   */
  public delete(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpEvent<Blob>>;
  /**
   * Construct a DELETE request which interprets the body as text and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `string`.
   */
  public delete(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<HttpEvent<string>>;
  /**
   * Construct a DELETE request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Object`.
   */
  public delete(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpEvent<Object>>;
  /**
   * Construct a DELETE request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `T`.
   */
  public delete<T>(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpEvent<T>>;
  /**
   * Construct a DELETE request which interprets the body as an `ArrayBuffer` and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `ArrayBuffer`.
   */
  public delete(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpResponse<ArrayBuffer>>;
  /**
   * Construct a DELETE request which interprets the body as a `Blob` and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Blob`.
   */
  public delete(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Blob>>;
  /**
   * Construct a DELETE request which interprets the body as text and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `string`.
   */
  public delete(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<HttpResponse<string>>;
  /**
   * Construct a DELETE request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Object`.
   */
  public delete(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Object>>;
  /**
   * Construct a DELETE request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `T`.
   */
  public delete<T>(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<T>>;
  /**
   * Construct a DELETE request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as an `Object`.
   */
  public delete(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object>;
  /**
   * Construct a DELETE request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as type `T`.
   */
  public delete<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T>;
  /**
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * DELETE request to be executed on the server. See the individual overloads for
   * details of `delete()`'s return type based on the provided options.
   */
  public delete(url: string, options: {
    headers?: HttpHeaders | { [header: string]: string | string[] },
    observe?: HttpObserve,
    params?: HttpParams | { [param: string]: string | string[] },
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
    withCredentials?: boolean,
  } = {}): Observable<any> {
    return this.request<any>('DELETE', url, options as any);
  }

  /**
   * Use this method if request should not affect on global progress bar.
   *
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * DELETE request to be executed on the server. See the individual overloads for
   * details of `delete()`'s return type based on the provided options.
   */
  public deleteInBackground(url: string, options: {
    headers?: HttpHeaders | { [header: string]: string | string[] },
    observe?: HttpObserve,
    params?: HttpParams | { [param: string]: string | string[] },
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
    withCredentials?: boolean,
  } = {}): Observable<any> {
    return this.request<any>('DELETE', url, options as any);
  }

  /**
   * Construct a GET request which interprets the body as an `ArrayBuffer` and returns it.
   *
   * @return an `Observable` of the body as an `ArrayBuffer`.
   */
  public get(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<ArrayBuffer>;
  /**
   * Construct a GET request which interprets the body as a `Blob` and returns it.
   *
   * @return an `Observable` of the body as a `Blob`.
   */
  public get(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<Blob>;
  /**
   * Construct a GET request which interprets the body as text and returns it.
   *
   * @return an `Observable` of the body as a `string`.
   */
  public get(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<string>;
  /**
   * Construct a GET request which interprets the body as an `ArrayBuffer` and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `ArrayBuffer`.
   */
  public get(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpEvent<ArrayBuffer>>;
  /**
   * Construct a GET request which interprets the body as a `Blob` and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Blob`.
   */
  public get(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpEvent<Blob>>;
  /**
   * Construct a GET request which interprets the body as text and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `string`.
   */
  public get(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<HttpEvent<string>>;
  /**
   * Construct a GET request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Object`.
   */
  public get(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpEvent<Object>>;
  /**
   * Construct a GET request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `T`.
   */
  public get<T>(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpEvent<T>>;
  /**
   * Construct a GET request which interprets the body as an `ArrayBuffer` and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `ArrayBuffer`.
   */
  public get(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpResponse<ArrayBuffer>>;
  /**
   * Construct a GET request which interprets the body as a `Blob` and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Blob`.
   */
  public get(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Blob>>;
  /**
   * Construct a GET request which interprets the body as text and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `string`.
   */
  public get(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<HttpResponse<string>>;
  /**
   * Construct a GET request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Object`.
   */
  public get(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Object>>;
  /**
   * Construct a GET request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `T`.
   */
  public get<T>(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<T>>;
  /**
   * Construct a GET request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as an `Object`.
   */
  public get(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object>;
  /**
   * Construct a GET request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as type `T`.
   */
  public get<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T>;
  /**
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * GET request to be executed on the server. See the individual overloads for
   * details of `get()`'s return type based on the provided options.
   */
  public get(url: string, options: {
    headers?: HttpHeaders | { [header: string]: string | string[] },
    observe?: HttpObserve,
    params?: HttpParams | { [param: string]: string | string[] },
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
    withCredentials?: boolean,
  } = {}): Observable<any> {
    return this.request<any>('GET', url, options as any);
  }

  /**
   * Use this method if request should not affect on global progress bar.
   *
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * GET request to be executed on the server. See the individual overloads for
   * details of `get()`'s return type based on the provided options.
   */
  public getInBackground(url: string, options: {
    headers?: HttpHeaders | { [header: string]: string | string[] },
    observe?: HttpObserve,
    params?: HttpParams | { [param: string]: string | string[] },
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
    withCredentials?: boolean,
  } = {}): Observable<any> {
    return this.request<any>('GET', url, options as any);
  }

  /**
   * Construct a HEAD request which interprets the body as an `ArrayBuffer` and returns it.
   *
   * @return an `Observable` of the body as an `ArrayBuffer`.
   */
  public head(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<ArrayBuffer>;
  public head(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<Blob>;
  /**
   * Construct a HEAD request which interprets the body as text and returns it.
   *
   * @return an `Observable` of the body as a `string`.
   */
  public head(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<string>;
  /**
   * Construct a GET request which interprets the body as an `ArrayBuffer` and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `ArrayBuffer`.
   */
  public head(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpEvent<ArrayBuffer>>;
  /**
   * Construct a HEAD request which interprets the body as a `Blob` and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Blob`.
   */
  public head(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpEvent<Blob>>;
  /**
   * Construct a HEAD request which interprets the body as text and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `string`.
   */
  public head(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<HttpEvent<string>>;
  /**
   * Construct a HEAD request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Object`.
   */
  public head(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpEvent<Object>>;
  /**
   * Construct a HEAD request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `T`.
   */
  public head<T>(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpEvent<T>>;
  /**
   * Construct a HEAD request which interprets the body as an `ArrayBuffer` and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `ArrayBuffer`.
   */
  public head(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpResponse<ArrayBuffer>>;
  /**
   * Construct a HEAD request which interprets the body as a `Blob` and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Blob`.
   */
  public head(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Blob>>;
  /**
   * Construct a HEAD request which interprets the body as text and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `string`.
   */
  public head(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<HttpResponse<string>>;
  /**
   * Construct a HEAD request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Object`.
   */
  public head(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Object>>;
  /**
   * Construct a HEAD request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `T`.
   */
  public head<T>(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<T>>;
  /**
   * Construct a HEAD request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as an `Object`.
   */
  public head(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object>;
  /**
   * Construct a HEAD request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as type `T`.
   */
  public head<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T>;
  /**
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * HEAD request to be executed on the server. See the individual overloads for
   * details of `head()`'s return type based on the provided options.
   */
  public head(url: string, options: {
    headers?: HttpHeaders | { [header: string]: string | string[] },
    observe?: HttpObserve,
    params?: HttpParams | { [param: string]: string | string[] },
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
    withCredentials?: boolean,
  } = {}): Observable<any> {
    return this.request<any>('HEAD', url, options as any);
  }

  /**
   * Construct a JSONP request for the given URL and name of the callback parameter.
   *
   * @return an `Observable` of the response object as an `Object`
   */
  public jsonp(url: string, callbackParam: string): Observable<Object>;
  /**
   * Construct a JSONP request for the given URL and name of the callback parameter.
   *
   * @return an `Observable` of the response object as type `T`.
   */
  public jsonp<T>(url: string, callbackParam: string): Observable<T>;
  /**
   * Constructs an `Observable` which, when subscribed, will cause a request
   * with the special method `JSONP` to be dispatched via the interceptor pipeline.
   *
   * A suitable interceptor must be installed (e.g. via the `HttpClientJsonpModule`).
   * If no such interceptor is reached, then the `JSONP` request will likely be
   * rejected by the configured backend.
   */
  public jsonp<T>(url: string, callbackParam: string): Observable<T> {
    return this.request<any>('JSONP', url, {
      params      : new HttpParams().append(callbackParam, 'JSONP_CALLBACK'),
      observe     : 'body',
      responseType: 'json',
    });
  }

  /**
   * Make an OPTIONS request which interprets the body as an `ArrayBuffer` and returns it.
   *
   * @return an `Observable` of the body as an `ArrayBuffer`.
   */
  public options(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<ArrayBuffer>;
  /**
   * Construct an OPTIONS request which interprets the body as a `Blob` and returns it.
   *
   * @return an `Observable` of the body as a `Blob`.
   */
  public options(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<Blob>;
  /**
   * Construct a OPTIONS request which interprets the body as text and returns it.
   *
   * @return an `Observable` of the body as a `string`.
   */
  public options(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<string>;
  /**
   * Construct an OPTIONS request which interprets the body as an `ArrayBuffer` and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `ArrayBuffer`.
   */
  public options(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpEvent<ArrayBuffer>>;
  /**
   * Construct an OPTIONS request which interprets the body as a `Blob` and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Blob`.
   */
  public options(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpEvent<Blob>>;
  /**
   * Construct an OPTIONS request which interprets the body as text and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `string`.
   */
  public options(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<HttpEvent<string>>;
  /**
   * Construct an OPTIONS request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Object`.
   */
  public options(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpEvent<Object>>;
  /**
   * Construct an OPTIONS request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `T`.
   */
  public options<T>(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpEvent<T>>;
  /**
   * Construct an OPTIONS request which interprets the body as an `ArrayBuffer` and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `ArrayBuffer`.
   */
  public options(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpResponse<ArrayBuffer>>;
  /**
   * Construct an OPTIONS request which interprets the body as a `Blob` and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Blob`.
   */
  public options(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Blob>>;
  /**
   * Construct an OPTIONS request which interprets the body as text and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `string`.
   */
  public options(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<HttpResponse<string>>;
  /**
   * Construct an OPTIONS request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Object`.
   */
  public options(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Object>>;
  /**
   * Construct an OPTIONS request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `T`.
   */
  public options<T>(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<T>>;
  /**
   * Construct an OPTIONS request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as an `Object`.
   */
  public options(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object>;
  /**
   * Construct an OPTIONS request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as type `T`.
   */
  public options<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T>;
  /**
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * OPTIONS request to be executed on the server. See the individual overloads for
   * details of `options()`'s return type based on the provided options.
   */
  public options(url: string, options: {
    headers?: HttpHeaders | { [header: string]: string | string[] },
    observe?: HttpObserve,
    params?: HttpParams | { [param: string]: string | string[] },
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
    withCredentials?: boolean,
  } = {}): Observable<any> {
    return this.request<any>('OPTIONS', url, options as any);
  }

  /**
   * Construct a PATCH request which interprets the body as an `ArrayBuffer` and returns it.
   *
   * @return an `Observable` of the body as an `ArrayBuffer`.
   */
  public patch(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<ArrayBuffer>;
  /**
   * Construct a PATCH request which interprets the body as a `Blob` and returns it.
   *
   * @return an `Observable` of the body as a `Blob`.
   */
  public patch(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<Blob>;
  /**
   * Construct a PATCH request which interprets the body as text and returns it.
   *
   * @return an `Observable` of the body as a `string`.
   */
  public patch(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<string>;
  /**
   * Construct a PATCH request which interprets the body as an `ArrayBuffer` and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `ArrayBuffer`.
   */
  public patch(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpEvent<ArrayBuffer>>;
  /**
   * Construct a PATCH request which interprets the body as a `Blob` and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Blob`.
   */
  public patch(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpEvent<Blob>>;
  /**
   * Construct a PATCH request which interprets the body as text and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `string`.
   */
  public patch(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<HttpEvent<string>>;
  /**
   * Construct a PATCH request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Object`.
   */
  public patch(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpEvent<Object>>;
  /**
   * Construct a PATCH request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `T`.
   */
  public patch<T>(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpEvent<T>>;
  /**
   * Construct a PATCH request which interprets the body as an `ArrayBuffer` and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `ArrayBuffer`.
   */
  public patch(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpResponse<ArrayBuffer>>;
  /**
   * Construct a PATCH request which interprets the body as a `Blob` and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Blob`.
   */
  public patch(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Blob>>;
  /**
   * Construct a PATCH request which interprets the body as text and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `string`.
   */
  public patch(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<HttpResponse<string>>;
  /**
   * Construct a PATCH request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Object`.
   */
  public patch(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Object>>;
  /**
   * Construct a PATCH request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `T`.
   */
  public patch<T>(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<T>>;
  /**
   * Construct a PATCH request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as an `Object`.
   */
  public patch(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object>;
  /**
   * Construct a PATCH request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as type `T`.
   */
  public patch<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T>;
  /**
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * PATCH request to be executed on the server. See the individual overloads for
   * details of `patch()`'s return type based on the provided options.
   */
  public patch(url: string, body: any | null, options: {
    headers?: HttpHeaders | { [header: string]: string | string[] },
    observe?: HttpObserve,
    params?: HttpParams | { [param: string]: string | string[] },
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
    withCredentials?: boolean,
  } = {}): Observable<any> {
    return this.request<any>('PATCH', url, addBody(options, body));
  }

  /**
   * Use this method if request should not affect on global progress bar.
   *
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * PATCH request to be executed on the server. See the individual overloads for
   * details of `patch()`'s return type based on the provided options.
   */
  public patchInBackground(url: string, body: any | null, options: {
    headers?: HttpHeaders | { [header: string]: string | string[] },
    observe?: HttpObserve,
    params?: HttpParams | { [param: string]: string | string[] },
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
    withCredentials?: boolean,
  } = {}): Observable<any> {
    return this.request<any>('PATCH', url, addBody(options, body));
  }

  /**
   * Construct a POST request which interprets the body as an `ArrayBuffer` and returns it.
   *
   * @return an `Observable` of the body as an `ArrayBuffer`.
   */
  public post(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<ArrayBuffer>;
  /**
   * Construct a POST request which interprets the body as a `Blob` and returns it.
   *
   * @return an `Observable` of the body as a `Blob`.
   */
  public post(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<Blob>;
  /**
   * Construct a POST request which interprets the body as text and returns it.
   *
   * @return an `Observable` of the body as a `string`.
   */
  public post(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<string>;
  /**
   * Construct a PATCH request which interprets the body as an `ArrayBuffer` and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `ArrayBuffer`.
   */
  public post(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpEvent<ArrayBuffer>>;
  /**
   * Construct a POST request which interprets the body as a `Blob` and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Blob`.
   */
  public post(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpEvent<Blob>>;
  /**
   * Construct a POST request which interprets the body as text and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `string`.
   */
  public post(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<HttpEvent<string>>;
  /**
   * Construct a POST request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Object`.
   */
  public post(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpEvent<Object>>;
  /**
   * Construct a POST request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `T`.
   */
  public post<T>(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpEvent<T>>;
  /**
   * Construct a POST request which interprets the body as an `ArrayBuffer` and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `ArrayBuffer`.
   */
  public post(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpResponse<ArrayBuffer>>;
  /**
   * Construct a POST request which interprets the body as a `Blob` and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Blob`.
   */
  public post(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Blob>>;
  /**
   * Construct a POST request which interprets the body as text and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `string`.
   */
  public post(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<HttpResponse<string>>;
  /**
   * Construct a POST request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Object`.
   */
  public post(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Object>>;
  /**
   * Construct a POST request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `T`.
   */
  public post<T>(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<T>>;
  /**
   * Construct a POST request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as an `Object`.
   */
  public post(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object>;
  /**
   * Construct a POST request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as type `T`.
   */
  public post<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T>;
  /**
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * POST request to be executed on the server. See the individual overloads for
   * details of `post()`'s return type based on the provided options.
   */
  public post(url: string, body: any | null, options: {
    headers?: HttpHeaders | { [header: string]: string | string[] },
    observe?: HttpObserve,
    params?: HttpParams | { [param: string]: string | string[] },
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
    withCredentials?: boolean,
  } = {}): Observable<any> {
    return this.request<any>('POST', url, addBody(options, body));
  }

  /**
   * Use this method if request should not affect on global progress bar.
   *
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * POST request to be executed on the server. See the individual overloads for
   * details of `post()`'s return type based on the provided options.
   */
  public postInBackground(url: string, body: any | null, options: {
    headers?: HttpHeaders | { [header: string]: string | string[] },
    observe?: HttpObserve,
    params?: HttpParams | { [param: string]: string | string[] },
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
    withCredentials?: boolean,
  } = {}): Observable<any> {
    return this.request<any>('POST', url, addBody(options, body));
  }

  /**
   * Construct a PUT request which interprets the body as an `ArrayBuffer` and returns it.
   *
   * @return an `Observable` of the body as an `ArrayBuffer`.
   */
  public put(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<ArrayBuffer>;
  /**
   * Construct a PUT request which interprets the body as a `Blob` and returns it.
   *
   * @return an `Observable` of the body as a `Blob`.
   */
  public put(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<Blob>;
  /**
   * Construct a PUT request which interprets the body as text and returns it.
   *
   * @return an `Observable` of the body as a `string`.
   */
  public put(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<string>;
  /**
   * Construct a PUT request which interprets the body as an `ArrayBuffer` and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `ArrayBuffer`.
   */
  public put(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpEvent<ArrayBuffer>>;
  /**
   * Construct a PUT request which interprets the body as a `Blob` and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Blob`.
   */
  public put(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpEvent<Blob>>;
  /**
   * Construct a PUT request which interprets the body as text and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `string`.
   */
  public put(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<HttpEvent<string>>;
  /**
   * Construct a PUT request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Object`.
   */
  public put(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpEvent<Object>>;
  /**
   * Construct a PUT request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `T`.
   */
  public put<T>(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpEvent<T>>;
  /**
   * Construct a PUT request which interprets the body as an `ArrayBuffer` and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `ArrayBuffer`.
   */
  public put(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpResponse<ArrayBuffer>>;
  /**
   * Construct a PUT request which interprets the body as a `Blob` and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Blob`.
   */
  public put(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Blob>>;
  /**
   * Construct a PUT request which interprets the body as text and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `string`.
   */
  public put(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'text';
    withCredentials?: boolean;
  }): Observable<HttpResponse<string>>;
  /**
   * Construct a PUT request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `Object`.
   */
  public put(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Object>>;
  /**
   * Construct a PUT request which interprets the body as JSON and returns the full response.
   *
   * @return an `Observable` of the `HttpResponse` for the request, with a body type of `T`.
   */
  public put<T>(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<T>>;
  /**
   * Construct a PUT request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as an `Object`.
   */
  public put(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object>;
  /**
   * Construct a PUT request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as type `T`.
   */
  public put<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T>;
  /**
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * POST request to be executed on the server. See the individual overloads for
   * details of `post()`'s return type based on the provided options.
   */
  public put(url: string, body: any | null, options: {
    headers?: HttpHeaders | { [header: string]: string | string[] },
    observe?: HttpObserve,
    params?: HttpParams | { [param: string]: string | string[] },
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
    withCredentials?: boolean,
  } = {}): Observable<any> {
    return this.request<any>('PUT', url, addBody(options, body));
  }

  /**
   * Use this method if request should not affect on global progress bar.
   *
   * Constructs an `Observable` which, when subscribed, will cause the configured
   * POST request to be executed on the server. See the individual overloads for
   * details of `post()`'s return type based on the provided options.
   */
  public putInBackground(url: string, body: any | null, options: {
    headers?: HttpHeaders | { [header: string]: string | string[] },
    observe?: HttpObserve,
    params?: HttpParams | { [param: string]: string | string[] },
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
    withCredentials?: boolean,
  } = {}): Observable<any> {
    return this.request<any>('PUT', url, addBody(options, body));
  }

  protected getRequestOptionArgs(options?: Options): Options {
    if (options == undefined) {
      options = {} as Options;
    }
    if (options.headers == undefined) {
      options.headers = new HttpHeaders();
    }
    options.headers = (options.headers as HttpHeaders)
      .append('Authorization', 'Bearer ' + this.cookieService.get(this.tokenKey));
    return options;
  }
}

/**
 * Construct an instance of `HttpRequestOptions<T>` from a source `HttpMethodOptions` and
 * the given `body`. Basically, this clones the object and adds the body.
 */
function addBody<T>(options: {
                      headers?: HttpHeaders | { [header: string]: string | string[] },
                      observe?: HttpObserve,
                      params?: HttpParams | { [param: string]: string | string[] },
                      reportProgress?: boolean,
                      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
                      withCredentials?: boolean,
                    },
                    body: T | null): any {
  return {
    body,
    headers        : options.headers,
    observe        : options.observe,
    params         : options.params,
    reportProgress : options.reportProgress,
    responseType   : options.responseType,
    withCredentials: options.withCredentials,
  };
}

export declare type Options = {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  observe?: HttpObserve;
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
};
