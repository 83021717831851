import {catchError, tap} from 'rxjs/operators';
import {Headers, Http, Request, RequestOptions, RequestOptionsArgs, Response} from '@angular/http';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {CookieService} from '../cookie-service';
import {_throw as throwError} from 'rxjs/observable/throw';

/**
 * @deprecated use {@link HttpClientWrapper}.
 */
@Injectable()
export class HttpWrapper {

  private tokenKey = 'access_token';

  private tokenValidity: Subject<boolean> = new Subject<boolean>();

  constructor(private http: Http, private cookieService: CookieService) {
  }

  /**
   * @deprecated
   */
  public request(url: string | Request, options?: RequestOptions): Observable<Response> {
    return this.intercept(this.http.request(url, options));
  }

  /**
   * @deprecated
   */
  public get(url: string, options?: RequestOptions): Observable<Response> {
    return this.intercept(this.http.get(url, this.getRequestOptionArgs(options)));
  }

  /**
   * @deprecated
   */
  public post(url: string, body: any, options?: RequestOptions): Observable<Response> {
    return this.intercept(this.http.post(url, body, this.getRequestOptionArgs(options)));
  }

  /**
   * @deprecated
   */
  public put(url: string, body: any, options?: RequestOptions): Observable<Response> {
    return this.intercept(this.http.put(url, body, this.getRequestOptionArgs(options)));
  }

  /**
   * @deprecated
   */
  public delete(url: string, options?: RequestOptions): Observable<Response> {
    return this.intercept(this.http.delete(url, this.getRequestOptionArgs(options)));
  }

  /**
   * @deprecated
   */
  public head(url: string, options?: RequestOptions): Observable<Response> {
    return this.intercept(this.http.head(url, this.getRequestOptionArgs(options)));
  }

  /**
   * @deprecated
   */
  public getRequestOptionArgs(options?: RequestOptions): RequestOptionsArgs {
    if (options == undefined) {
      options = new RequestOptions();
    }
    if (options.headers == undefined) {
      options.headers = new Headers();
    }
    options.headers.append('Authorization', 'Bearer ' + this.cookieService.get(this.tokenKey));
    // options.headers.append('Content-Type', 'application/json');
    return options;
  }

  /**
   * @deprecated use {@link UnauthorizedInterceptor}
   */
  public intercept(observable: Observable<Response>): Observable<Response> {
    return observable.pipe(
      tap((response: Response) => {
        if (response.status !== 401) {
          this.tokenValidity.next(true);
        }
      }),
      catchError((err: Response) => {
        if (err.status === 401) {
          this.tokenValidity.next(false);
          return throwError(err);
        } else {
          return throwError(err);
        }
      })
    );
  }

  /**
   * @deprecated use {@link TokenInfoService.tokenValidityObservable}.
   */
  public isTokenValid(): Observable<boolean> {
    return this.tokenValidity;
  }
}
