import {HttpRequest} from '@angular/common/http';
import {CookieService} from '../../cookie-service';

export function addToken(
    request: HttpRequest<any>,
    cookieService: CookieService
) {
    return request.clone({
        setHeaders: {
            Authorization: `Bearer ${cookieService.get('access_token')}`,
        },
    });
}
