/**
 * @author Vahagn Lazyan.
 * @since 2.1.0
 */
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {ErrorObservable} from 'rxjs/observable/ErrorObservable';
import {catchError, first, switchMap, tap} from 'rxjs/operators';
import {CookieService} from '../../cookie-service';
import {AuthSettings} from '../auth-settings.config';
import {TokenInfoService} from './token-info.service';
import {addToken} from './token.utilities';

/**
 * Interceptor for Http 401 requests.
 * You need to register it in your modules providers list.
 */
@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    constructor(
        private tokenInfoService: TokenInfoService,
        private cookieService: CookieService
    ) {}

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (
            req.url.includes(AuthSettings.OAUTH_SERVICE_CHECK_TOKEN_ENDPOINT) ||
            req.url.includes(AuthSettings.OAUTH_SERVICE_TOKEN_ENDPOINT)
        ) {
            return next.handle(req);
        }
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (
                        event.status !== 401 &&
                        (req.headers.has('Authorization') ||
                            (req.body && req.body['access_token']))
                    ) {
                        this.tokenInfoService.tokenValiditySubject.next(true);
                    }
                }
            }),
            catchError((err, caught) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.tokenInfoService.tokenValiditySubject.next(false);
                    return this.tokenInfoService.tokenUpdatedViaQuickLogin.pipe(
                        first(),
                        switchMap(updated =>
                            updated
                                ? next.handle(addToken(req, this.cookieService))
                                : ErrorObservable.create(err)
                        )
                    );
                }
                return ErrorObservable.create(err);
            })
        );
    }
}
