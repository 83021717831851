import {LoginResponseType, VerificationType} from '../api/http';

export class LoginResponse {
  constructor(private _loginResponseType: LoginResponseType,
              private _messageId: string = '',
              private _placeholders: string[] = [],
              private _verificationType?: VerificationType,
              private _blockTime?: Date,
              private _lockTimeInMinutes?: number) {
  }

  get loginResponse(): LoginResponseType {
    return this._loginResponseType;
  }

  get messageId(): string {
    return this._messageId;
  }

  get placeholders(): string[] {
    return this._placeholders;
  }

  get verificationType(): VerificationType {
    return this._verificationType;
  }

  get blockTime(): Date {
    return this._blockTime;
  }

  get lockTimeInMinutes(): number {
    return this._lockTimeInMinutes;
  }


}
