/**
 * @author gnuni.gevorgyan
 */
export class UserData {
  private _fullName: string;
  private _userId: number;
  private _username: string;
  private _isSamlUser: boolean;

  get isSamlUser(): boolean {
    return this._isSamlUser;
  }

  set isSamlUser(value: boolean) {
    this._isSamlUser = value;
  }

  get fullName(): string {
    return this._fullName;
  }

  set fullName(value: string) {
    this._fullName = value;
  }

  get userId(): number {
    return this._userId;
  }

  set userId(value: number) {
    this._userId = value;
  }

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
  }
}
