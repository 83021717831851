import {HttpRequest} from '@angular/common/http';

export class RequestPatternModel {
    constructor(
        private method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'HEAD',
        private urlPattern: string | RegExp
    ) {}

    public match<T>(request: HttpRequest<T>): boolean {
        return request.method === this.method && this.matchPattern(request.url);
    }

    private matchPattern(url: string): boolean {
        if (this.urlPattern instanceof RegExp) {
            return this.urlPattern.test(url);
        }
        return this.urlPattern.includes(url);
    }
}
