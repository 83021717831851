import {map} from 'rxjs/operators';
import {AuthenticationService} from '../authentication.service';
import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {_throw as throwError} from 'rxjs/observable/throw';
import {LoginResponse, UserData} from '../../model/index';
import {LoginResponseType} from '../http/index';

/**
 * Created by gnuni.gevorgyan on 9/28/2016.
 */
@Injectable()
export class DummyAuthenticatedUserService extends AuthenticationService {

  /**
   * Handle errors
   * @param error
   * @returns {ErrorObservable}
   */
  private static handleError(error: any) {
    const errMsg = (error.message) ? error.message :
                 error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg);
    return throwError(errMsg);
  }

  public redirectUrl = '';
  private loggedOn: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private token: string;

  private loginUrl: string;

  private loginApiUrlKey = 'login.api.url.key';

  constructor() {
    super();
    const currToken: string = localStorage.getItem('id_token');
    if (currToken) {
      this.loggedOn.next(true);
      this.token = currToken;
    } else {
      this.loggedOn.next(false);
    }
  }

  public login(username: string, password: string): Observable<LoginResponse> {
    // Todo http call to server

    if (username === 'guest' && password === 'guest') {
      this.loggedOn.next(true);
      this.token = 'guest';
      localStorage.setItem('id_token', this.token);
    } else if (username === 'admin' && password === 'admin') {
      this.loggedOn.next(true);
      this.token = 'admin';
      localStorage.setItem('id_token', this.token);
    } else {
      this.loggedOn.next(false);
    }
    return of(new LoginResponse(LoginResponseType.SUCCESS));
  }

  public logout(withoutRequest?: boolean): void {
    localStorage.removeItem('id_token');
    this.loggedOn.next(false);
  }

  public hasPermission(key: string): boolean {
    if (this.token === 'admin') {
      return true;
    } else if (key === 'nav.bar.myWorkspace' || key === 'nav.bar.myWorkspace.overview' ||
      key === 'nav.bar.myWorkspace.households') {
      return true;
    }
    return false;
  }

  public getUserData(): Observable<UserData> {
    const userData: UserData = new UserData();
    if (this.token === 'guest') {
      userData.fullName = 'Guest Guest';
    } else {
      userData.fullName = 'Asya Kostanyan';
    }
    return of(userData);
  }

  public isLoggedOn(): Observable<boolean> {
    return this.loggedOn.pipe(map(isLogged => isLogged));
  }

  public isGuestUser(): Observable<boolean> {
    return of(this.token === 'guest');
  }

  public loginAsGuest(): Observable<LoginResponse> {
    return of(new LoginResponse(LoginResponseType.SUCCESS));
  }

  public isLoggedOnAsGuestUser(): Observable<boolean> {
    return of(false);
  }

  public resend(): Observable<boolean> {
    return of(true);
  }

  public verifyCode(code: string): Observable<boolean> {
    return of(true);
  }

  checkToken(): void {
  }


}
