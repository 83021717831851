export class AuthSettings {
  public static OAUTH_SERVICE_TOKEN_ENDPOINT = '/oauth/token';
  public static OAUTH_SERVICE_CHECK_TOKEN_ENDPOINT = '/oauth/check_token';
  public static IS_SAML_USER = 'is_saml_user';
  public static OAUTH_SERVICE_USERDATA_ENDPOINT = '/me';
  public static OAUTH_SERVICE_LOGOUT_ENDPOINT = '/logout';
  public static CLIENT_ID = 'client';
  public static CLIENT_SECRET = 'secret';
  public static OAUTH_SERVICE_URL_KEY = 'oauth-service-url';
  public static TOKEN_KEY_NAME = 'access_token';
  public static GUEST_USER_NAME = 'Guest';
  public static GUEST_PASSWORD = '123456';
  public static GUEST_USER_ID = -3;
  public static VERIFY_CODE = '/verifycode';
  public static RESEND = '/resend';
}
