/**
 * @author Vahagn Lazyan.
 * @since 2.1.0
 */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class TokenInfoService {
    protected tokenValidity: Subject<boolean> = new Subject<boolean>();

    private _tokenUpdatedViaQuickLogin = new Subject<boolean>();

    get tokenValiditySubject(): Subject<boolean> {
        return this.tokenValidity;
    }

    get tokenValidityObservable(): Observable<boolean> {
        return this.tokenValidity.asObservable();
    }

    get tokenUpdatedViaQuickLogin(): Subject<boolean> {
        return this._tokenUpdatedViaQuickLogin;
    }
}
